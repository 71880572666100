<template>
  <v-dialog
    :value="isOpen"
    persistent
    max-width="600px"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="change-csv-wrp">
      <v-toolbar dark color="primary">
        <v-btn icon dark :loading="is_loading" text @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Upload CSV</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn :loading="is_loading" text @click="UploadCSV">
            <v-icon class="mr-2" dark> mdi-cloud-upload </v-icon> Upload
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div v-if="headers">
          <v-simple-table class="mt-6" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="background: #f3f3f3"
                    class="header-csv-th"
                    v-for="[key, value] in objectEntries(headers)"
                    :key="key"
                  >
                    <div class="csv-header-item">
                      <div class="csv-header-item__title">
                        {{ key }}
                      </div>
                      <v-icon style="font-size: 13px" class="mr-3 ml-3"
                        >mdi-arrow-right</v-icon
                      >
                      <div class="csv-header-item__select">
                        <CSVHeaderSelect
                          :item="value"
                          :changeTitle="(value) => (headers[key] = value)"
                          :disableItems="disableItems"
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, ind) in data">
                  <tr :key="ind" v-if="ind < 11">
                    <td
                      class="header-csv-th"
                      v-for="(value, i) in item"
                      :key="i"
                    >
                      {{ value }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
          <div class="others-items">
            You see only the first records of the file
          </div>
        </div>

        <!-- <v-container>
          <v-simple-table v-if="headers && headers.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Old field name</th>
                  <th>New field name</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in headers" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item }}</td>
                  <td>
                    <CSVHeaderSelect
                      :item="item"
                      :changeTitle="(value) => changeTitle(value, index)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> 
        </v-container> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import Papa from "papaparse";

import CSVHeaderSelect from "./CSVHeaderSelect.vue";
import { importCSV_v2 } from "@/store/modules/contacts/api";

import { requestStatus } from "@/services/services";
export default {
  name: "ChangeCSV",
  components: {
    CSVHeaderSelect,
  },
  data: () => ({
    isOpen: false,
    is_loading: false,
    headers: null,
    data: [],
    file: null,
    oldHeaders: [],
  }),
  computed: {
    disableItems() {
      return Object.values(this.headers);
    },
  },
  mounted() {
    window.getApp.$on("OPEN_CSV_CHANGE_DIALOG", (data) => {
      this.isOpen = true;
      this.headers = data.headers.reduce((obj, key) => {
        obj[key] = key;
        return obj;
      }, {});
      this.data = data.data;
      this.file = data.file;
      this.oldHeaders = JSON.parse(JSON.stringify(data.headers));
    });
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        this.headers = null;
        this.oldHeaders = [];
        this.data = [];
        this.file = null;
      }
    },
  },
  methods: {
    objectEntries(obj) {
      return Object.entries(obj);
    },
    changeTitle(value, index) {
      this.headers[index] = value;
    },
    UploadCSV() {
      let cyryllicFileName = this.cyrillicToLatin(this.file.name);
      let newFile = new File([this.file], cyryllicFileName, {
        type: this.file.type,
      });

      const headersObject = JSON.parse(JSON.stringify(this.headers));

      for (const key in headersObject) {
        if (!Object.prototype.hasOwnProperty.call(headersObject, key)) continue;

        if (!headersObject[key]) {
          headersObject[key] = key;
        }
      }

      let data = {
        file: newFile,
        headers: headersObject,
      };
      this.is_loading = true;

      let formData = new FormData();
      formData.append("file", data.file);
      formData.append("headers", JSON.stringify(data.headers));

      importCSV_v2(formData, cyryllicFileName)
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Uploaded");
            this.isOpen = false;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    cyrillicToLatin(text) {
      const translitMap = {
        А: "A",
        Б: "B",
        В: "V",
        Г: "G",
        Ґ: "G",
        Д: "D",
        Е: "E",
        Ё: "Yo",
        Є: "Ye",
        Ж: "Zh",
        З: "Z",
        И: "I",
        Й: "Y",
        І: "I",
        Ї: "Yi",
        К: "K",
        Л: "L",
        М: "M",
        Н: "N",
        О: "O",
        П: "P",
        Р: "R",
        С: "S",
        Т: "T",
        У: "U",
        Ф: "F",
        Х: "Kh",
        Ц: "Ts",
        Ч: "Ch",
        Ш: "Sh",
        Щ: "Shch",
        Ъ: "",
        Ы: "Y",
        Ь: "",
        Э: "E",
        Ю: "Yu",
        Я: "Ya",
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        ґ: "g",
        д: "d",
        е: "e",
        ё: "yo",
        є: "ie",
        ж: "zh",
        з: "z",
        и: "i",
        й: "y",
        і: "i",
        ї: "i",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "kh",
        ц: "ts",
        ч: "ch",
        ш: "sh",
        щ: "shch",
        ъ: "",
        ы: "y",
        ь: "",
        э: "e",
        ю: "iu",
        я: "ia",
      };

      return text
        .replace(/./g, (char) => {
          return translitMap[char] || char;
        })
        .replace(/[^\w.]/g, "_");
    },
  },
};
</script>

<style lang="scss">
.change-csv-wrp {
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
  }
}

.others-items {
  font-size: 13px;
  margin-top: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-csv-th {
  min-width: 220px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
.csv-header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .v-select__selection {
    font-size: 12px !important;
  }
  &__select {
    width: 120px;
  }
}
</style>
