<template>
  <div>
    <div
      class="group-item mb-2"
      :class="{
        'group-item-last': lastItem,
        'group-item-first': firstItem,
        'not-valid-group-item': !validGroup,
      }"
      style="cursor: pointer"
    >
      <div class="group-item__navigation">
        <div class="d-flex">
          <v-select
            style="max-width: 120px"
            v-model="rule.groupType"
            :items="typeItems"
            dense
            item-value="value"
            item-text="label"
            solo
            hide-details
            @change="changeGroupType(rule.groupType)"
            label="Type"
          ></v-select>
          <v-select
            class="ml-2"
            style="max-width: 150px"
            v-model="rule.condition"
            :items="conditionItems"
            dense
            item-value="value"
            item-text="label"
            solo
            hide-details
          ></v-select>
          <v-select
            v-if="paths && paths.length && rule.groupType === 'nested'"
            class="ml-2"
            style="max-width: 120px"
            v-model="rule.path"
            :items="paths"
            dense
            item-value="value"
            item-text="label"
            solo
            hide-details
            @change="changeGroupPath()"
            label="Path"
          ></v-select>
        </div>
        <div>
          <v-btn
            :disabled="disableGroupActions"
            class="ml-2"
            @click="addRule()"
            depressed
            small
          >
            Add rule
          </v-btn>
          <v-btn
            :disabled="disableGroupActions"
            class="ml-2"
            @click="addGroup()"
            depressed
            small
          >
            Add Group
          </v-btn>
          <v-btn
            class="ml-2"
            depressed
            small
            color="error"
            @click="deleteGroup(rule)"
          >
            Delete
          </v-btn>
        </div>
      </div>
      <template v-if="rule.rules && rule.rules.length">
        <draggable
          v-model="rule.rules"
          group="people"
          @start="drag = true"
          @end="dragEndEvent"
        >
          <div v-for="(item, index) in rule.rules" :key="index">
            <GroupItem
              v-if="item.condition"
              :rule="item"
              @delete-group="() => deleteItem(item)"
              :lastItem="index === rule.rules.length - 1"
            />
            <RuleItem
              v-else
              :rule="item"
              @delete-item="() => deleteItem(item)"
              :lastItem="index === rule.rules.length - 1"
              :rulePath="rule.path"
              :groupType="rule.groupType"
            />
          </div>
        </draggable>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupItem from "./GroupItem";
import RuleItem from "./RuleItem";
import draggable from "vuedraggable";
export default {
  name: "GroupItem",
  props: ["rule", "lastItem", "firstItem"],
  data() {
    return {
      drag: false,
      typeItems: [
        {
          value: "bool",
          label: "Bool",
        },
        {
          value: "nested",
          label: "Nested",
        },
      ],
      conditionItems: [
        {
          value: "must",
          label: "Must",
        },
        {
          value: "must_not",
          label: "Must not",
        },
        {
          value: "should",
          label: "Should",
        },
        // {
        //   value: "filter",
        //   label: "Filter",
        // },
      ],
      isComponentAlive: true,
      validGroup: true,
    };
  },
  computed: {
    ...mapState({
      paths: (state) => state.contacts.rulePathes,
    }),
    disableGroupActions() {
      return this.rule.groupType == "nested" && !this.rule.path;
    },
  },
  components: {
    GroupItem,
    RuleItem,
    draggable,
  },
  destroyed() {
    this.isComponentAlive = false;
  },
  mounted() {
    window.getApp.$on("VALID_RULE", () => {
      if (this.isComponentAlive) this.validateForm();
    });
  },
  methods: {
    async dragEndEvent() {
      this.drag = false;
      let newRules = await [...this.rule.rules];
      this.rule.rules = await [];
      this.rule.rules = await newRules;
      window.getApp.$emit("ON_CHANGE_RULES");
    },
    changeGroupType(value) {
      this.rule.rules = [];
      setTimeout(() => {
        this.rule.rules = [
          {
            id: "",
            field: "",
            operator: "",
            value: null,
          },
        ];
        window.getApp.$emit("ON_CHANGE_RULES");
      }, 100);
      if (value === "bool") {
        this.rule.path = null;
      }
    },
    changeGroupPath() {
      this.rule.rules = [];
      setTimeout(() => {
        this.rule.rules = [
          {
            id: "",
            field: "",
            operator: "",
            value: null,
          },
        ];
        window.getApp.$emit("ON_CHANGE_RULES");
      }, 100);
    },
    validateForm() {
      let valid = false;

      switch (this.rule.groupType) {
        case "bool":
          valid = this.rule.rules.length ? true : false;
          break;
        case "nested":
          valid = this.rule.rules.length && this.rule.path ? true : false;
          break;
      }

      this.validGroup = valid;
      window.getApp.$emit("SET_VALID_ERRORS", this.validGroup);
    },
    async deleteItem(groupToDelete) {
      let newRules = await [...this.rule.rules];
      this.rule.rules = await [];
      const index = newRules.indexOf(groupToDelete);
      if (index !== -1) {
        let arr = await newRules
          .slice(0, index)
          .concat(newRules.slice(index + 1));
        this.rule.rules = arr;
      }
      window.getApp.$emit("ON_CHANGE_RULES");
    },
    deleteGroup(index) {
      this.$emit("delete-group", this.rule);
      window.getApp.$emit("ON_CHANGE_RULES");
      //   this.rule.rules.splice(index, 1);
    },
    addRule() {
      this.rule.rules.push({
        id: "",
        field: "",
        operator: "",
        value: null,
      });
    },
    addGroup(index) {
      this.rule.rules.push({
        condition: "must",
        groupType: "bool",
        path: "",
        rules: [
          {
            id: "",
            field: "",
            operator: "",
            value: null,
          },
        ],
      });
    },
  },
};
</script>
