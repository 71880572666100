<template>
  <div>
    <div
      v-if="rules"
      class="group-item mb-2"
      :class="{
        'group-item-last': lastItem,
        'not-valid-group-item': !validFrom,
      }"
      style="cursor: pointer"
    >
      <div class="group-item__selects">
        <v-select
          style="max-width: 300px"
          class="mr-2"
          :items="rulesList"
          item-value="id"
          item-text="label"
          v-model="selectedRule"
          label="Rule"
          color="info"
          return-object
          hide-details
          solo
          dense
          @change="selected(selectedRule)"
          :disabled="groupType === 'nested' && !rulePath"
        ></v-select>
        <v-select
          v-if="selectedRule"
          style="max-width: 300px"
          class="mr-2"
          :items="
            selectedRule.operators ? selectedRule.operators : queryOperators
          "
          v-model="queryOperator"
          @change="onChangequeryOperator"
          dense
          solo
          hide-details
          :disabled="selectedRule && selectedRule.plugin === 'datetimepicker'"
        ></v-select>
        <template v-if="queryOperator != 'exists' && selectedRule">
          <template v-if="selectedRule.values">
            <MultiSelect
              v-if="queryOperator == 'terms'"
              :changeQueryValue="(value) => changeQueryValue(value)"
              :ruleId="selectedRule.id"
              :queryValue="queryValue"
              :items="
                Object.entries(selectedRule.values).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))
              "
            />
            <Select
              v-else
              :changeQueryValue="(value) => changeQueryValue(value)"
              :ruleId="selectedRule.id"
              :queryValue="queryValue"
              :items="
                Object.entries(selectedRule.values).map(([key, value]) => ({
                  label: value,
                  value: key,
                }))
              "
            />
          </template>

          <template
            v-if="selectedRule.plugin && selectedRule.plugin == 'selectize'"
          >
            <SelectizeItemMulti
              v-if="queryOperator == 'terms'"
              :changeQueryValue="(value) => changeQueryValue(value)"
              :ruleId="selectedRule.id"
              :queryValue="queryValue"
              :rule="selectedRule"
            />
            <SelectizeItem
              v-else
              :changeQueryValue="(value) => changeQueryValue(value)"
              :ruleId="selectedRule.id"
              :queryValue="queryValue"
              :rule="selectedRule"
            />
          </template>

          <template v-if="selectedRule.plugin == 'textarea'">
            <TextareaItem
              :queryOperator="queryOperator"
              :changeQueryValue="(value) => changeQueryValue(value)"
              :ruleId="selectedRule.id"
              :queryValue="queryValue"
            />
          </template>

          <template
            v-if="
              selectedRule.plugin && selectedRule.plugin === 'datetimepicker'
            "
          >
            <DateRange
              :ruleId="selectedRule.id"
              :query="rule.query"
              :queryValue="queryValue"
              :changeQueryValue="(value) => changeQueryValue(value)"
            />
          </template>
          <template
            v-if="
              queryOperator == 'range' &&
              selectedRule.plugin != 'datetimepicker'
            "
          >
            <IntegerRangeItem
              :queryOperator="queryOperator"
              :ruleId="selectedRule.id"
              :query="rule.query"
              :queryValue="queryValue"
              :changeQueryValue="(value) => changeQueryValue(value)"
            />
          </template>
        </template>

        <v-btn @click="deleteItem()" color="error" icon x-small>
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <!-- {{ rule }} -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

// import { debounce } from "lodash";
// import * as BroadcastsAPI from "@/store/modules/broadcasts/api";
import SelectizeItem from "./modules/SelectizeItem.vue";
import SelectizeItemMulti from "./modules/SelectizeItemMulti.vue";
import MultiSelect from "./modules/MultiSelect.vue";
import Select from "./modules/Select.vue";
import DateRange from "./modules/DateRange.vue";
import TextareaItem from "./modules/TextareaItem.vue";
import IntegerRangeItem from "./modules/IntegerRangeItem.vue";
export default {
  components: {
    SelectizeItem,
    SelectizeItemMulti,
    MultiSelect,
    Select,
    DateRange,
    TextareaItem,
    IntegerRangeItem,
  },
  name: "RuleItem",
  props: ["rule", "lastItem", "rulePath", "groupType"],
  data() {
    return {
      queryOperator: "",
      queryValue: "",
      queryOperators: ["match", "term", "terms", "exists", "range"],
      // queryOperators: [
      //   {
      //     value: "match",
      //     label: "Match",
      //   },
      //   {
      //     value: "term",
      //     label: "Term",
      //   },
      //   {
      //     value: "terms",
      //     label: "Terms",
      //   },
      //   {
      //     value: "exists",
      //     label: "Exists",
      //   },
      //   {
      //     value: "range",
      //     label: "Range",
      //   },
      // ],

      selectedRule: null,
      isComponentAlive: true,
      validFrom: true,
    };
  },
  watch: {
    queryOperator(queryOperator) {
      this.rule.queryOperator = queryOperator;
      this.rule.value = "";
      this.setQuery();
    },
    queryValue(queryValue) {
      if (queryValue) {
        this.rule.value = queryValue;
        this.setQuery();
      }
    },
  },
  computed: {
    ...mapState({
      rules: (state) => state.contacts.elastic_rules,
    }),
    rulesList() {
      if (this.rulePath) {
        return this.rules.filter(
          (item) => item.path && item.path == this.rulePath
        );
      } else {
        return this.rules.filter((item) => !item.path);
      }
    },
  },
  destroyed() {
    this.isComponentAlive = false;
  },
  mounted() {
    window.getApp.$on("VALID_RULE", () => {
      if (this.isComponentAlive) this.validateForm();
    });
    if (this.rule && this.rule.id) {
      this.selectedRule = this.rules.find((item) => item.id === this.rule.id);
      this.queryValue = this.rule.value ? this.rule.value : "";
      this.queryOperator = this.rule.queryOperator
        ? this.rule.queryOperator
        : "";

      if (
        this.selectedRule &&
        this.selectedRule.plugin &&
        this.selectedRule.plugin == "datetimepicker"
      ) {
        this.queryOperator = "range";
      }
    }
  },
  methods: {
    onChangequeryOperator() {
      this.queryValue = null;
      this.setQuery();
    },
    changeQueryValue(value) {
      window.getApp.$emit("ON_CHANGE_RULES");
      this.queryValue = value;
      this.rule.value = value;
      this.setQuery();
    },
    validateForm() {
      switch (this.queryOperator) {
        case "exists":
          this.validFrom =
            this.selectedRule && this.rule.query && this.queryOperator
              ? true
              : false;
          break;
        default:
          if (
            this.selectedRule &&
            this.selectedRule.plugin === "datetimepicker"
          ) {
            this.validFrom = this.queryValue && this.rule.query ? true : false;
          } else {
            this.validFrom =
              this.selectedRule &&
              this.rule.query &&
              this.queryOperator &&
              this.queryValue
                ? true
                : false;
          }
      }
      window.getApp.$emit("SET_VALID_ERRORS", this.validFrom);
    },
    selected(selected) {
      this.rule.value = "";
      this.queryValue = "";
      this.rule.id = selected.id;
      this.rule.field = selected.id;
      this.queryOperator = "";
      this.queryOperator = selected.operators
        ? selected.operators[0]
        : this.queryOperators[0];
      if (selected && selected.plugin === "datetimepicker") {
        this.queryOperator = "range";
      }
      this.setQuery();
    },
    deleteItem(index) {
      this.$emit("delete-item", this.rule);
    },
    setQuery() {
      if (this.queryOperator && this.selectedRule.id) {
        let query = {};

        switch (this.queryOperator) {
          case "exists":
            query[this.queryOperator] = { ["field"]: this.selectedRule.id };
            break;
          case "range":
            query[this.queryOperator] = {
              [this.selectedRule.id]: this.queryValue,
            };
            break;
          default:
            query[this.queryOperator] = {
              [this.selectedRule.id]: this.queryValue,
            };
        }

        this.rule["query"] = query;
      }
    },
  },
};
</script>

<style lang="scss">
.group-item {
  .mx-input {
    height: 36px !important;
    margin-right: 12px !important;
    border: none !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
