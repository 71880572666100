var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Email")]),_c('th',[_vm._v("First Last name")]),_c('th',[_vm._v("Country")]),_c('th',[_vm._v("Phone")]),_c('th',[_vm._v("Carrier")]),_c('th',[_vm._v("Offer")]),_c('th',[_vm._v("Updated at")]),_c('th',[_vm._v("Created at")])])]),_c('tbody',_vm._l((_vm.data),function(item,index){return _c('tr',{key:index},[_c('td',[_c('span',[_c('v-btn',{staticClass:"ml-0 pa-1",attrs:{"text":"","x-small":"","to":`contacts/${item.uuid}`}},[_vm._v(" "+_vm._s(item.email && item.email.address ? item.email.address : "without email")+" ")]),(item.email && item.email.is_valid)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):(item.email)?_c('v-icon',{attrs:{"color":item.email.is_valid === false ? 'red' : 'orange',"small":""}},[_vm._v("mdi-close")]):_vm._e()],1)]),_c('td',[_vm._v(" "+_vm._s(item.first_name ? item.first_name : "")+" "+_vm._s(item.last_name ? item.last_name : "")+" ")]),_c('td',[(item.country)?_c('country-flag',{attrs:{"country":item.country,"size":"small"}}):_vm._e()],1),_c('td',[(item.phone && item.phone.number)?_c('span',[_vm._v(" "+_vm._s(item.phone.number)+" "),(item.phone.is_valid)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":item.phone.is_valid === false ? 'red' : 'orange',"small":""}},[_vm._v("mdi-close")]),(
                item.phone.has_watsapp === true ||
                item.phone.has_watsapp === false
              )?_c('v-icon',{attrs:{"color":item.phone.has_watsapp === false ? 'gray' : '#008000',"small":""}},[_vm._v("mdi-whatsapp")]):_vm._e()],1):_vm._e()]),_c('td',[(item.phone && item.phone.carrier)?_c('span',[_vm._v(" "+_vm._s(item.phone.carrier)+" ")]):_vm._e()]),_c('td',[(
              item.sources &&
              item.sources.length &&
              _vm.sortSources(item.sources) &&
              _vm.sortSources(item.sources).offer
            )?_c('span',{class:{
              'no-offer-name': !_vm.sortSources(item.sources).offer.name,
            }},[_vm._v(" "+_vm._s(_vm.sortSources(item.sources).offer.name ? _vm.sortSources(item.sources).offer.name : _vm.sortSources(item.sources).offer.key)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }