<template>
  <div>
    <v-text-field
      class="mr-2"
      style="max-width: 300px"
      @input="changeSelected"
      dense
      solo
      v-model="selected"
      hide-details
      :disabled="!queryOperator"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "queryOperator"],
  data() {
    return {
      selected: "",
    };
  },
  created() {
    if (this.queryValue) this.selected = this.queryValue;
  },
  watch: {
    queryValue(queryValue) {
      if (!queryValue) this.selected = "";
    },
  },
  methods: {
    changeSelected() {
      this.changeQueryValue(this.selected ? this.selected : "");
    },
  },
};
</script>

<style></style>
