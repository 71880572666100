<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Broadcast</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name *"
                v-model="name"
                persistent-hint
                required
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="base_url"
                label="Base URL *"
                required
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="country"
                :items="countries"
                :loading="isLoadingCountry"
                :search-input.sync="searchCountry"
                label="Country*"
                placeholder="Search"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                dense
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="BROADCAST_TARGETS"
                label="Target"
                item-text="name"
                item-value="value"
                v-model="broadcast_target"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="offer"
                :items="offers"
                :loading="isLoadingOffer"
                :search-input.sync="searchOffer"
                label="Offer*"
                placeholder="Search"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                dense
                required
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="networkList"
                label="Network"
                v-model="network"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <tags ref="tags" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="life_days"
                label="Life days"
                type="number"
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="max_links"
                label="Links Per Part"
                type="number"
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="parts"
                label="Parts"
                type="number"
                hide-details
                dense
                required
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-switch
                v-model="broadcast_type"
                :label="broadcast_type ? 'auto broadcast' : 'manual broadcast'"
              ></v-switch>
            </v-col> -->
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleOpenModal">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="onCreateClick"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  LOAD_COUNTRIES,
  LOAD_OFFERS,
} from "../../store/modules/broadcasts/types";
import { requestStatus, BROADCAST_TARGETS } from "@/services/services";
import { createBroadcast_v2 } from "@/store/modules/broadcasts/api";
import Tags from "@/components/Tags";
import _ from "lodash";

export default {
  name: "CreateBroadcast",
  components: { Tags },
  props: {
    toggleOpenModal: Function,
    isOpen: Boolean,
    filter_conditions: Object,
  },
  data: () => ({
    is_loading: false,
    name: null,
    base_url: null,
    network: "general",
    networkList: ["wlt", "general", "gr", "gift", "customs"],
    max_links: null,
    life_days: 7,
    parts: 1,
    // broadcast_type: false,
    flow: "",
    country: "",
    offer: null,
    searchCountry: "",
    searchOffer: "",
    isLoadingCountry: false,
    isLoadingOffer: false,
    broadcast_target: BROADCAST_TARGETS[0].value,
  }),
  computed: {
    ...mapState({
      countries: (state) => state.broadcasts.countries,
      offers: (state) => state.broadcasts.offers,
      status: (state) => state.broadcasts.status,
    }),
    BROADCAST_TARGETS: () => BROADCAST_TARGETS,
  },
  methods: {
    ...mapActions({
      onLoadCountries: `broadcasts/${LOAD_COUNTRIES}`,
      onLoadOffers: `broadcasts/${LOAD_OFFERS}`,
    }),

    onWaitChangeCountry() {
      if (
        this.searchCountry &&
        (this.searchCountry.length >= 2 || this.searchCountry === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountry}`);
      }
    },

    onWaitChangeOffer() {
      if (
        this.searchOffer &&
        (this.searchOffer.length >= 2 || this.searchOffer === "")
      ) {
        this.onLoadOffers(`search=${this.searchOffer}`);
      }
    },
    onCreateClick() {
      if (!this.name || !this.base_url || !this.offer || !this.country) {
        this.$toaster.error("Set fields");
        return;
      }

      if (!this.parts || this.parts < 1 || this.parts > 25) {
        this.$toaster.error("Parts must be between 1 and 10");
        return;
      }

      let data = {
        filter_conditions: this.filter_conditions,
        name: this.name,
        base_url: this.base_url,
        network: this.network,
        max_links: this.max_links || 0,
        country: this.country,
        offer: this.offer,
        flow: this.flow,
        // broadcast_type: +this.broadcast_type,
        broadcast_target: this.broadcast_target,
        tags: this.$refs.tags.getTagsList(),
      };
      if (this.life_days) {
        data.life_days = this.life_days;
      }
      if (this.parts) {
        data.parts = this.parts;
      }
      createBroadcast_v2(data).then((response) => {
        if (response.status === requestStatus.success) {
          this.toggleOpenModal();
        } else {
          this.$toaster.error(
            response.messages || "Oops, something went wrong."
          );
        }
      });
    },
  },
  watch: {
    isOpen(newValue) {
      if (newValue) {
        this.searchCountry
          ? this.onWaitChangeCountry(this.searchCountry)
          : this.onLoadCountries("limit=10");

        this.searchOffer
          ? this.onWaitChangeOffer(this.searchOffer)
          : this.onLoadOffers("limit=10");
      } else {
        // this.name = null;
        // this.base_url = null;
        // this.network = "general";
        // this.max_links = null;
        // this.life_days = 7;
        // this.flow = "";
        // this.country = "";
        // this.offer = null;
        // this.searchCountry = "";
        // this.searchOffer = "";
        // this.broadcast_type = false;
      }
    },
    status(newValue) {
      this.isLoadingCountry = newValue.load_countries === requestStatus.loading;
      this.isLoadingOffer = newValue.load_offers === requestStatus.loading;
    },
    searchCountry() {
      this.onWaitChangeCountry();
    },
    searchOffer() {
      this.onWaitChangeOffer();
    },
  },
  created() {
    this.onWaitChangeCountry = _.debounce(this.onWaitChangeCountry, 500);
    this.onWaitChangeOffer = _.debounce(this.onWaitChangeOffer, 500);
  },
};
</script>

<style scoped></style>
