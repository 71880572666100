<template>
  <div>
    <!-- {{ selected }} -->
    <v-autocomplete
      ref="SelectizeItemMulti"
      :items="lists"
      v-model="selected"
      placeholder="Search"
      item-text="name"
      item-value="value"
      solo
      dense
      hide-details
      class="mr-2"
      clearable
      return-object
      no-filter
      @change="changeSelected"
      @click="clickEvent"
      multiple
      :search-input.sync="searchString"
      :menu-props="{
        maxHeight: 200,
        offsetY: true,
        offsetOverflow: false,
        transition: false,
      }"
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @click="toggleSelectAll">
          <v-list-item-content>Select All</v-list-item-content>
        </v-list-item>
      </template>
      <template slot="selection" slot-scope="{ item, index }">
        <v-chip small color="primery" close @click:close="removeItem(index)">
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { SELECTIZE_SETTINGS } from "@/services/services";
import { loadDataForElasticRequest } from "@/store/modules/broadcasts/api";

export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "rule"],
  data() {
    return {
      selected: [],
      value: "",
      dataList: [],
      searchString: "",
    };
  },
  computed: {
    lists() {
      if (this.selected) {
        return [...this.dataList, ...this.selected];
      } else {
        return this.dataList;
      }
    },
  },
  created() {
    if (this.queryValue && this.queryValue.length) {
      loadDataForElasticRequest(
        this.rule.data_uri,
        `value=${this.queryValue.join(",")}`
      ).then((response) => {
        if (response.status === "success") {
          if (
            response.response.data.results &&
            response.response.data.results.length
          ) {
            this.selected = response.response.data.results;
            this.dataList = response.response.data.results;
          } else {
            this.selected = [];
            this.dataList = [];
            this.changeQueryValue("");
          }
        } else {
          this.selected = [];
          this.dataList = [];
          this.changeQueryValue("");
        }
      });
    }
  },
  watch: {
    ruleId() {
      this.dataList = [];
      this.selected = [];
    },
    searchString(searchString) {
      this.fetchData(searchString);
    },
  },
  methods: {
    toggleSelectAll() {
      this.selected = [...new Set([...this.selected, ...this.dataList])];
      console.log("toggleSelectAll");
    },
    removeItem(index) {
      this.selected.splice(index, 1);
      this.changeSelected();
    },
    changeSelected() {
      // this.searchString = "";
      if (!this.selected || !this.selected.length) {
        this.dataList = [];
        this.changeQueryValue("");
      } else {
        let multivalue = this.selected.map((item) => item.value);
        this.changeQueryValue(multivalue);
      }
    },
    clickEvent() {
      if (!this.dataList.length) this.fetchData("");
    },
    fetchData(e) {
      if (e || e === "") {
        if (!this.selected || this.selected.name != e) {
          loadDataForElasticRequest(
            this.rule.data_uri,
            `search=${e ? e : ""}`
          ).then((response) => {
            if (response.status === "success") {
              if (
                response.response.data.results &&
                response.response.data.results.length
              ) {
                this.dataList = response.response.data.results;
              } else {
                this.dataList = [];
              }
            } else {
              this.dataList = [];
            }
          });
        }
      }
    },
  },
};
</script>

<style></style>
