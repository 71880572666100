<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Template</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Enter template name"
          v-model="templateName"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCloseDialog"> Close </v-btn>
        <v-btn color="blue darken-1" text type="button" @click="onSaveTemplate">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createTemplate_v2 } from "@/store/modules/contacts/api";
import { requestStatus } from "@/services/services";
export default {
  data() {
    return {
      isOpen: false,
      templateName: "",
      rules: null,
    };
  },
  mounted() {
    window.getApp.$on("OPEN_SAVE_TEMPLATE_DIALOG", (rules) => {
      this.isOpen = true;
      this.rules = JSON.parse(JSON.stringify(rules));
    });
  },
  methods: {
    onCloseDialog() {
      this.templateName = "";
      this.rules = null;
      this.isOpen = false;
    },
    onSaveTemplate() {
      if (!this.templateName && this.rules) {
        this.$toaster.error("Set name and apply rules");
        return;
      }
      createTemplate_v2({
        name: this.templateName,
        filter_conditions: this.rules,
      }).then((response) => {
        if (response.status === requestStatus.success) {
          this.$toaster.success("Template saved");
          this.onCloseDialog();
        } else {
          this.$toaster.error(
            response.messages || "Oops, something went wrong."
          );
        }
      });
    },
  },
};
</script>

<style></style>
