<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Export</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name *"
                v-model="name"
                persistent-hint
                required
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Description"
                v-model="description"
                persistent-hint
                required
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="max_contacts"
                label="Max contacts *"
                type="number"
                hide-details
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleOpenModal">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="onCreateClick"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import { exportBroadcast } from "@/store/modules/broadcasts/api";

export default {
  name: "CreateBExportroadcast",
  props: {
    toggleOpenModal: Function,
    isOpen: Boolean,
    filter_conditions: Object,
  },
  data: () => ({
    is_loading: false,
    name: null,
    max_contacts: null,
    description: "",
  }),

  methods: {
    onCreateClick() {
      if (!this.name || !this.max_contacts) {
        this.$toaster.error("Set fields");
        return;
      }

      let data = {
        filter_conditions: this.filter_conditions,
        name: this.name,
        description: this.description,
        max_contacts: this.max_contacts,
      };

      exportBroadcast(data).then((response) => {
        if (response.status === requestStatus.success) {
          // this.$toaster.success("Export file created");
          this.name = "";
          this.max_contacts = null;
          this.description = "";
          this.toggleOpenModal();
        } else {
          this.$toaster.error(
            response.messages || "Oops, something went wrong."
          );
        }
      });
    },
  },
};
</script>

<style scoped></style>
