<template>
  <div>
    <GroupsWrp :saveQuery="(data) => saveQuery(data)" ref="childsQueryRules" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupsWrp from "./components/GroupsWrp.vue";

export default {
  components: {
    GroupsWrp,
  },
  data() {
    return {
      requestQuery: null,
      validErrors: [],
    };
  },
  destroyed() {
    window.getApp.$off("SET_VALID_ERRORS");
  },
  mounted() {
    window.getApp.$on("SET_VALID_ERRORS", (value) => {
      this.validErrors.push(value);
    });
  },
  computed: {
    ...mapState({
      rules: (state) => state.contacts.elastic_rules,
    }),
  },
  methods: {
    saveQuery(data) {
      this.query = data;
    },
    getQuery() {
      this.validErrors = [];
      window.getApp.$emit("VALID_RULE");
      const hasFalse = this.validErrors.some((item) => item === false);
      if (!hasFalse) {
        let requestQuery = this.$refs.childsQueryRules.getRequsetQuery();
        this.requestQuery = requestQuery;
        return this.requestQuery;
      } else {
        if (!this.getRenderRules().rules.length) {
          this.requestQuery = {};
          return this.requestQuery;
        }
      }
    },
    getRenderRules() {
      let rules = this.$refs.childsQueryRules.getRenderRules();
      return rules;
    },
  },
};
</script>

<style></style>
