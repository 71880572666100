<template>
  <div class="mb-6">
    <div class="group-item mb-2 group-item-wrp">
      <div class="group-item__navigation">
        <div class="d-flex">
          <v-btn
            :disabled="disabledCreate"
            @click="addGlobalGroup"
            depressed
            small
          >
            Create
          </v-btn>
          <!-- <v-select
            class="ml-2"
            style="max-width: 120px"
            v-model="group_type"
            :items="typeItems"
            dense
            item-value="value"
            item-text="label"
            solo
            hide-details
          ></v-select> -->
        </div>
      </div>
      <template
        v-if="renderRules && renderRules.rules && renderRules.rules.length"
      >
        <div v-for="(rule, ruleIndex) in renderRules.rules" :key="ruleIndex">
          <GroupItem
            v-if="rule.condition"
            :rule="rule"
            :lastItem="ruleIndex === renderRules.rules.length - 1"
            @delete-group="() => deleteItem(rule)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import GroupItem from "./GroupItem.vue";
export default {
  props: ["rules"],
  components: {
    GroupItem,
  },
  data() {
    return {
      group_type: "bool",
      typeItems: [
        {
          value: "bool",
          label: "Bool",
        },
        {
          value: "nested",
          label: "Nested",
        },
      ],
      conditionItems: [
        {
          value: "must",
          label: "Must",
        },
        {
          value: "must_not",
          label: "Must not",
        },
        {
          value: "should",
          label: "Should",
        },
        // {
        //   value: "filter",
        //   label: "Filter",
        // },
      ],
      ruleItem: {
        id: "",
        operator: "",
        value: "",
      },
      renderRules: null,
      defaultRules: {
        rules: [],
      },
    };
  },
  computed: {
    disabledCreate() {
      return this.renderRules &&
        this.renderRules.rules &&
        this.renderRules.rules.length
        ? true
        : false;
    },
  },
  destroyed() {
    window.getApp.$off("SET_RENDER_RULES");
    window.getApp.$off("CLEAR_RENDER_RULES");
    window.getApp.$off("VALID_RULE");
  },
  mounted() {
    window.getApp.$on("SET_RENDER_RULES", (rules) => {
      this.renderRules = null;
      setTimeout(async () => {
        this.renderRules = (await rules) ? rules : this.defaultRules;
        window.getApp.$emit("GET_CONTACTS_DATA");
      }, 100);
    });
    window.getApp.$on("CLEAR_RENDER_RULES", () => {
      this.renderRules = {
        rules: [],
      };
    });

    window.getApp.$on("VALID_RULE", () => {
      window.getApp.$emit(
        "SET_VALID_ERRORS",
        this.renderRules && this.renderRules.rules.length ? true : false
      );
    });

    this.renderRules = this.defaultRules;
  },
  methods: {
    processRules(arr, topLevel = false) {
      return arr.map((item) => {
        if (item.condition) {
          let newObj = {
            [topLevel ? item.condition : "bool"]: topLevel
              ? this.processRules(item.rules)
              : { [item.condition]: this.processRules(item.rules) }, // Рекурсивно обробляємо вкладені rules
          };
          // if (item.condition === "should") {
          //   if (!topLevel) {
          //     Object.assign(newObj.bool, { test: "123131" });
          //   }
          // }
          if (item.groupType === "bool") {
            return newObj;
          } else {
            return {
              nested: {
                path: item.path,
                query: newObj,
              },
            };
          }
        } else {
          return item.query;
        }
      });
    },
    addGlobalGroup() {
      let newGroup = {
        condition: "must",
        groupType: "bool",
        rules: [
          {
            id: "",
            field: "",
            operator: "",
            value: null,
          },
        ],
      };
      this.renderRules.rules.push(newGroup);
    },
    addGlobalRule(ruleIndex) {
      this.renderRules.rules.push({
        id: "",
        field: "",
        operator: "",
        value: null,
      });
    },
    deleteItem(groupToDelete) {
      const index = this.renderRules.rules.indexOf(groupToDelete);
      if (index !== -1) {
        this.renderRules.rules.splice(index, 1);
      }
      window.getApp.$emit("ON_CHANGE_RULES");
    },
    deleteGroup(index) {
      this.$emit("delete-group", this.rule);
      window.getApp.$emit("ON_CHANGE_RULES");
      //   this.rule.rules.splice(index, 1);
    },
    getRenderRules() {
      return this.renderRules;
    },
    getRequsetQuery() {
      let data = this.processRules(this.renderRules.rules, true);
      if (data) {
        const transformedObject = data.reduce(
          (result, obj) => {
            for (const key in obj) {
              if (key == "nested") {
                // console.log(obj);
                result = {
                  nested: {
                    path: obj.nested.path,
                    query: {
                      bool: {
                        ...obj.nested.query,
                      },
                    },
                  },
                };
                // for (const keyNested in obj[key].query) {
                //   if (Array.isArray(obj[key].query[keyNested])) {
                //     result.bool[keyNested] = result.bool[keyNested] || [];

                //     result.bool[keyNested].push({
                //       [key]: {
                //         path: obj.nested.path,
                //         query: {
                //           bool: obj.nested.query,
                //         },
                //       },
                //     });
                //   }
                // }
              } else {
                if (Array.isArray(obj[key])) {
                  result.bool[key] = result.bool[key] || [];
                  result.bool[key] = result.bool[key].concat(obj[key]);
                  // result.bool[key] = result.bool[key].push(obj[key]);
                }
              }
            }
            // console.log(result);
            if (result.bool) {
              // console.log("its bool");
              return { bool: result.bool };
            } else {
              return { nested: result.nested };
            }
          },
          { bool: {} }
        );
        return { query: transformedObject };
        // if (this.group_type === "bool") {
        //   return { query: { bool: transformedObject.bool } };
        // } else {
        //   return {
        //     nested: {
        //       path: "",
        //       query: { bool: transformedObject.bool },
        //     },
        //   };
        // }
      }
    },
  },
};
</script>
