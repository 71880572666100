<template>
  <div>
    <!-- {{ selected }} -->

    <v-autocomplete
      ref="SelectizeItem"
      :items="dataList"
      v-model="selected"
      placeholder="Search"
      item-text="name"
      item-value="value"
      solo
      dense
      hide-details
      class="mr-2"
      clearable
      return-object
      no-filter
      @update:search-input="fetchData"
      @change="changeSelected"
      @click="clickEvent"
      :menu-props="{
        maxHeight: 200,
        offsetY: true,
        offsetOverflow: false,
        transition: false,
      }"
    ></v-autocomplete>
  </div>
</template>

<script>
import { SELECTIZE_SETTINGS } from "@/services/services";
import { loadDataForElasticRequest } from "@/store/modules/broadcasts/api";
export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "rule"],
  data() {
    return {
      selected: null,
      value: "",
      dataList: [],
    };
  },
  created() {
    if (this.queryValue) {
      loadDataForElasticRequest(
        this.rule.data_uri,
        `value=${this.queryValue}`
      ).then((response) => {
        if (response.status === "success") {
          if (
            response.response.data.results &&
            response.response.data.results.length
          ) {
            let selectedItem = response.response.data.results.find(
              (item) => item.value == this.queryValue
            );
            if (selectedItem) {
              this.selected = selectedItem;
              this.dataList = response.response.data.results;
            } else {
              this.selected = null;
              this.dataList = [];
              this.changeQueryValue("");
            }
          }
        } else {
          this.selected = null;
          this.dataList = [];
          this.changeQueryValue("");
        }
      });
    }
  },
  watch: {
    queryValue(queryValue) {
      if (!queryValue) this.selected = null;
    },
    ruleId() {
      this.dataList = [];
      this.selected = null;
    },
  },
  methods: {
    changeSelected() {
      if (!this.selected) this.dataList = [];
      this.changeQueryValue(this.selected ? this.selected.value : "");
    },
    clickEvent() {
      if (!this.dataList.length) this.fetchData("");
    },
    fetchData(e) {
      if (e || e === "") {
        if (!this.selected || this.selected.name != e) {
          loadDataForElasticRequest(
            this.rule.data_uri,
            `search=${e ? e : ""}`
          ).then((response) => {
            if (response.status === "success") {
              this.dataList = response.response.data.results;
            } else {
              this.dataList = [];
            }
          });
        }
      }
    },
  },
};
</script>

<style></style>
