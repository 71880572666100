<template>
  <div>
    <v-select
      style="max-width: 300px"
      class="mr-2"
      :items="items"
      item-value="value"
      item-text="label"
      v-model="selected"
      label="Value"
      color="info"
      hide-details
      solo
      dense
      @change="changeSelected"
      clearable
    ></v-select>
  </div>
</template>

<script>
export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "items"],
  data() {
    return {
      selected: "",
    };
  },
  created() {
    if (this.queryValue) this.selected = this.queryValue;
  },
  watch: {
    queryValue(queryValue) {
      if (!queryValue) this.selected = "";
    },
  },
  methods: {
    changeSelected() {
      this.changeQueryValue(this.selected ? this.selected : "");
    },
  },
};
</script>

<style></style>
