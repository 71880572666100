<template>
  <tags-input
    ref="tags"
    v-model="selectedTags"
    :add-tags-on-comma="true"
    :addTagsOnBlur="false"
    :existing-tags="existingTags"
    :sort-search-results="false"
    :typeahead="true"
    :typeahead-activation-threshold="1"
    :typeahead-hide-discard="true"
    element-id="tags"
    typeahead-style="dropdown"
    @change="onInputTag"
  ></tags-input>
</template>

<script>
import VoerroTagsInput from "@voerro/vue-tagsinput";
import { mapActions, mapState } from "vuex";
// import "@yaireo/tagify/dist/tagify.css";
import { LOAD_TAGS } from "../store/modules/broadcasts/types";
import _ from "lodash";

export default {
  name: "Tags",
  components: { "tags-input": VoerroTagsInput },
  props: ["propsTags"],
  data: () => ({
    search_tag: "",
    selectedTags: [],
    existingTags: [],
  }),
  computed: {
    ...mapState({
      tags_list: (state) => state.broadcasts.tags,
    }),
  },
  methods: {
    ...mapActions({
      loadTags: `broadcasts/${LOAD_TAGS}`,
    }),
    onInputTag: function (e) {
      this.search_tag = e;
      this.$refs.tags.searchSelection = -1;
      this.onWaitChange();
    },

    onWaitChange: function () {
      this.loadTags(`search=${this.search_tag}`);
    },
    getTagsList: function () {
      return this.selectedTags.map((item) => item.value);
    },
  },
  watch: {
    tags(newValue) {
      this.selectedTags = [
        ...newValue.map((item) => ({ value: item, key: item })),
      ];
    },
    tags_list(newValue) {
      let current_list = this.getTagsList();
      let list = [
        ...newValue.map((item) => ({ value: item.name, key: item.name })),
      ];
      this.existingTags = list.filter(
        (item) => !current_list.includes(item.key)
      );
      this.$refs.tags.searchResults = this.existingTags;
      this.loading = true;
    },
  },
  mounted() {
    if (this.propsTags && this.propsTags.length) {
      this.selectedTags = [
        ...this.propsTags.map((item) => ({ value: item, key: item })),
      ];
    }
    this.onWaitChange = _.debounce(this.onWaitChange, 500);
  },
};
</script>

<style scoped>
@import "../assets/tags.css";
</style>
