<template>
  <div class="d-flex">
    <v-text-field
      class="mr-2"
      style="max-width: 300px"
      @input="changeSelected"
      dense
      solo
      v-model="gte"
      hide-details
      :disabled="!queryOperator"
    ></v-text-field>
    <v-text-field
      class="mr-2"
      style="max-width: 300px"
      @input="changeSelected"
      dense
      solo
      v-model="lte"
      hide-details
      :disabled="!queryOperator"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "queryOperator"],
  data() {
    return {
      gte: "",
      lte: "",
    };
  },
  created() {
    // console.log(this.queryValue);
    if (this.queryValue) {
      if (this.queryValue.gte) this.gte = this.queryValue.gte;
      if (this.queryValue.lte) this.lte = this.queryValue.lte;
    }
  },
  watch: {
    queryValue(queryValue) {
      if (!queryValue) {
        this.gte = "";
        this.lte = "";
      }
    },
  },
  methods: {
    changeSelected() {
      var dateObj = {};
      if (this.gte) dateObj["gte"] = this.gte;
      if (this.lte) dateObj["lte"] = this.lte;
      this.changeQueryValue(
        dateObj && (dateObj.gte || dateObj.lte) ? dateObj : ""
      );
    },
  },
};
</script>

<style></style>
