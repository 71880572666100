<template>
  <div>
    <v-select
      class="mr-2"
      :items="items"
      item-value="value"
      item-text="label"
      v-model="selected"
      label="Value"
      color="info"
      hide-details
      solo
      dense
      multiple
      @change="changeSelected"
      clearable
    >
      <template slot="selection" slot-scope="{ item, index }">
        <v-chip small color="primery" close @click:close="removeItem(index)">
          {{ item.label }}
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: ["ruleId", "queryValue", "changeQueryValue", "items"],
  data() {
    return {
      selected: [],
    };
  },
  created() {
    if (this.queryValue && this.queryValue.length)
      this.selected = this.queryValue;
  },
  methods: {
    removeItem(index) {
      this.selected.splice(index, 1);
      this.changeSelected();
    },
    changeSelected() {
      this.changeQueryValue(
        this.selected && this.selected.length ? this.selected : ""
      );
    },
  },
};
</script>

<style></style>
