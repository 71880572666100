<template>
  <div>
    <v-select
      :items="headerItems"
      v-model="selected"
      item-value="value"
      item-text="label"
      return-object
      @change="changeTitle(selected ? selected.value : '')"
      hide-details
      :item-disabled="(value) => disableItems.includes(value.value)"
      solo
      dense
      clearable
    ></v-select>
  </div>
</template>

<script>
export default {
  props: ["changeTitle", "disableItems", "item"],
  data() {
    return {
      selected: null,
      headerItems: [
        {
          label: "Phone",
          value: "phone",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Name",
          value: "name",
        },
        {
          label: "First name",
          value: "first_name",
        },
        {
          label: "Last name",
          value: "last_name",
        },
        {
          label: "City",
          value: "city",
        },
        {
          label: "Zip",
          value: "zip",
        },
        {
          label: "Address",
          value: "address",
        },
        {
          label: "Gender",
          value: "gender",
        },
        {
          label: "Network",
          value: "network",
        },
        {
          label: "Offer",
          value: "offer",
        },
        {
          label: "Country",
          value: "country",
        },
        {
          label: "External id",
          value: "external_id",
        },
      ],
    };
  },
  created() {
    this.selected = this.headerItems.find((item) => (item.value == this.item));
  },
};
</script>

<style></style>
