<template>
  <div v-if="is_loading" class="loader-wrp">
    <v-progress-circular
      :size="30"
      indeterminate
      color="info"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: ["is_loading"],
};
</script>

<style lang="scss">
.loader-wrp {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.4);
  z-index: 99999;
}
</style>
