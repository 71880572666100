<template>
  <div class="d-flex">
    <date-picker
      class="mr-2"
      format="YYYY-MM-DD HH:mm"
      v-model="datatime_gte"
      type="datetime"
      placeholder="gte"
    ></date-picker>
    <date-picker
      placeholder="lte"
      class="mr-2"
      format="YYYY-MM-DD HH:mm"
      v-model="datatime_lte"
      type="datetime"
    ></date-picker>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  props: ["ruleId", "query", "changeQueryValue", "queryValue"],
  components: {
    DatePicker,
  },
  data() {
    return {
      datatime_gte: null,
      datatime_lte: null,
    };
  },
  watch: {
    datatime_gte() {
      this.createdQuery();
    },
    datatime_lte() {
      this.createdQuery();
    },
    queryValue(queryValue) {
      if (!queryValue) {
        this.datatime_gte = null;
        this.datatime_lte = null;
      }
    },
  },
  created() {
    if (this.query && this.query.range && this.query.range[this.ruleId]) {
      if (this.query.range[this.ruleId].gte)
        this.datatime_gte = moment(
          this.query.range[this.ruleId].gte,
          "YYYY-MM-DD HH:mm"
        ).toDate();
      if (this.query.range[this.ruleId].lte)
        this.datatime_lte = moment(
          this.query.range[this.ruleId].lte,
          "YYYY-MM-DD HH:mm"
        ).toDate();
    }
  },
  methods: {
    createdQuery() {
      var dateObj = {};
      if (this.datatime_gte)
        dateObj["gte"] =
          moment(this.datatime_gte).format("YYYY-MM-DDTHH:mm:ss") + "+00:00";
      if (this.datatime_lte)
        dateObj["lte"] =
          moment(this.datatime_lte).format("YYYY-MM-DDTHH:mm:ss") + "+00:00";
      this.changeQueryValue(
        dateObj && (dateObj.gte || dateObj.lte) ? dateObj : ""
      );
    },
  },
};
</script>

<style></style>
